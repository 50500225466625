@import "variables";
@import "framework/framework";

html {
	background: $color-red;
	color: $color-black;
	font-family: "Didot LT W05 Bold";
	font-size: $html-font-size;
	font-weight: normal;
	line-height: 1;
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(55px) 0 rem(20px) 0;
	text-align: center;

	@include phone-down {
		padding: rem(40px) 0 rem(20px) 0;
	}

	h1 {
		margin: 0;

		> img {
			margin: 0 auto;
			width: rem(190px);
			
			@include phone-down {
				width: 50%;
			}
		}
	}

	h2 {
		margin-top: 0;
		> img {
			margin: 0 auto;
		}

		.mobile{
			display: none;
			width: 90%;
			@include phone-down {
				display: block;
			}
		}
		.desktop{
			display: block;
			
			width: rem(760px);
			max-width: 100%;
			@include phone-down {
				display: none;
			}
		}
	}
}

main {
	nav {
		text-align: center;
		// margin-bottom: rem(100px);

		> ul {
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				display: inline-block;
				font-size: rem(20px);
				text-transform: lowercase;
				vertical-align: middle;
				@include phone-down {
					font-size: rem(17px);
				}

				& + li {
					margin-left: rem(57px);

					@include phone-down {
						margin-left: rem(20px);
					}
				}
			}
		}
	}

	#connect {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		padding-top: rem(40px);

		@include phone-down {
			display: block;
			text-align: center;
		}

		> div {
			flex: 0 1 auto;

			> .socials {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(20px);
					line-height: 1;
					vertical-align: middle;

					+ li {
						margin-left: rem(17px);
					}
				}
			}

			> form {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin-left: rem(40px);

				@include phone-down {
					justify-content: center;
					margin: rem(25px) auto 0 auto;
				}

				> input[type="email"] {
					background: $color-red;
					border: 1px solid $color-black;
					color: $color-black;
					display: block;
					flex: 0 1 auto;
					font-size: rem(15px);
					line-height: 1;
					max-width: 100%;
					padding: rem(10px) rem(15px);
					width: rem(230px);

					&:focus {
						border-color: $color-black;
						outline: 0;
					}

					@include placeholder {
						color: $color-black;
						text-transform: lowercase;
					}
				}

				> button {
					background: $color-black;
					border: 0;
					color: $color-red;
					flex: 0 0 auto;
					font-size: rem(15px);
					line-height: 1;
					margin-left: rem(10px);
					padding: rem(10px) rem(15px);
					text-transform: lowercase;

					@include hover-focus {
						background: $color-black;
						color: $color-white;
					}

				}
			}
		}
	}
}

footer {
	padding: rem(75px) 0 rem(10px) 0;

	@include phone-down {
		padding-top: rem(50px);
	}

	.copyright {
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: rem(8px);
		line-height: line-height(11px, 8px);
		text-align: center;
		text-transform: uppercase;

		> a {
			display: inline-block;
			margin-bottom: rem(5px);
			vertical-align: middle;

			&.rca {
				width: rem(40px);
			}

			&.visionary {
				margin-left: rem(5px);
				margin-top: rem(-3px);

				> img {
					height: rem(12px);
					width: auto;
				}
			}

			> svg {
				height: auto;
				width: 100%;

				> path {
					fill: $color-black;
				}
			}
		}

		> p {
			margin: 0;

			> a {
				color: $color-black;

				@include hover-focus {
					color: $color-white;
					text-decoration: underline;
				}
			}
		}
	}
}

.mfp-bg{
	opacity: .7;
}

.mfp-close{
	color: $color-white;
	font-size: rem(40px);
	opacity: 1;
}

#music{
	text-align: center;
	padding: 0 rem(10px);
	margin: rem(30px) auto;
	width: rem(600px);
	max-width: 100%;

	color: $color-red;
	text-transform: lowercase;

	

	p{
		font-size: rem(39px);
	}

	.owl-dot{
		width: rem(15px);
		height: rem(15px);
		border-radius: rem(8px);
		background-color: $color-gray;
		margin: rem(5px);
		@include hover-focus{
			background-color: $color-red;
		}
	}

	.item{
		margin-top: rem(60px);
		img{
			margin: 0 auto;
			width: rem(250px);
			height: auto;
		}
		p{
			font-size: rem(23px);
			margin: .5em auto .7em;
		}
		> .btn {
			background: $color-red;
			display: inline-block;
			border: 0;
			color: $color-black;
			font-size: rem(15px);
			line-height: 1;
			padding: rem(4px) rem(15px) rem(6px);
			text-transform: lowercase;

			@include hover-focus {
				background: $color-red;
				color: $color-white; 
			}

		}

		
	}
}